import axios, { $get, $post, $put, $delete, $postFile } from '@/plugins/axios';
// import { setToken } from '@/views/common/store'
const tansParams = (params) => {
	let result = "";
	Object.keys(params).forEach((key) => {
		if (
			!Object.is(params[key], undefined) &&
			!Object.is(params[key], null)
		) {
			result +=
				encodeURIComponent(key) +
				"=" +
				encodeURIComponent(params[key]) +
				"&";
		}
	});
	return result;
}
export default {
	namespaced: true,
	state: {
		tableList: [],
		total: 0,
		params: null,
		mmPath: ""
	},
	actions: {
		changeSchoolMessage({ state }, params) {
			console.log(params)


			return axios.post('/lock/universityInfo/updateUniversityInfoPassword', { ...params })


		},
		getExcel({ state }, params) {
			console.log(state.name)
			$get('/lock/universityInfo/exportExcel', { params: { ...params, titleFlag: "1", }, responseType: "blob" }).then(res => {
			})
		},
		getExcelTemplate() {
			axios.get('/lock/universityInfo/downloadExcelTemplate', { responseType: "blob" }).then(res => {
			})
		},
		getErrWhiteExcel({ state }, params) {
			$get('/lock/universityInfo/downloadErrorExcel', { params, responseType: "blob" }).then(res => {
			})
		},
		uploadExcelTemplate({ dispatch }, fd) {
			return new Promise((res, rej) => {
				$postFile('/lock/universityInfo/importExcel', fd).then(() => {
					dispatch('getTableData');
				}).catch(rej)
			})
		},
		getTableData({ commit }, params = {}) {
			commit("setParams", params);
			$get('/lock/universityInfo/page', {
				params: {
					pageNum: 1,
					pageSize: 50,
					...params
				}
			}).then(res => {
				commit("setTableList", res.list);
				commit("setTotal", res.total);
			})
		},
		addTableData({ dispatch }, params) {
			$post('/lock/universityInfo', params).then(() => {
				dispatch("getTableData");    //成功则返回页面
			})
		},
		modifyTableData({ dispatch, state }, params) {
			$put('/lock/universityInfo', params).then(() => {
				dispatch("getTableData", state.params);
			})
		},
		deleteTableData({ dispatch, state }, id) {
			$delete(`/lock/universityInfo/${id}`).then(() => {
				dispatch("getTableData", state.params);
			})
		},
		mmlogin({ state }, param) {
			/// 登录接口
			return new Promise((res, rej) => {
				const params = {
					// grant_type: "client_credentials",

				}
				$post('/auth/oauth/token?grant_type=client_credentials', params, {
					transformRequest: [(params) => {
						params.universityId && delete params.universityId;
						return tansParams(params)
					}],
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Authorization': 'Basic Y2xpZW50OmNsaWVudA==',
					},
					isLogin: true,
					needAll: true
				}).then((response) => {
					res(response.data.access_token)
				}).catch(rej)
			})
		},
		getMMmessage({ commit }) {
			$get("/lock/systemParameter", { doNotNeedLoading: true }).then(res => {
				res.forEach(item => {
					if (item.paramCode == 'mmPath') {
						commit('setMMpath', item.paramValue)
					}
				})
			})
		}
	},
	mutations: {
		setTableList: (state, payload) => {
			state.tableList = payload;
		},
		setTotal: (state, payload) => {
			state.total = payload;
		},
		setParams(state, payload) {
			state.params = payload
		},
		setMMpath(state, payload) {
			state.mmPath = payload
		}
	},

}