import { $get, $post, $put, $delete } from '@/plugins/axios';
// import { setToken } from '@/views/common/store'
export default {
	namespaced: true,
	state: {
		tableList: [],
		total: 0,
		params: null,
		schoolList: [],
	},
	actions: {
		getTableData({ commit }, params = {}) {
			commit("setParams", params);
			$get('/lock/setterInfo/page', {
				params: {
					pageNum: 1,
					pageSize: 20,
					...params
				}
			}).then(res => {
				commit("setTableList", res.list);
				commit("setTotal", res.total);
			})
		},
		addTableData({ dispatch }, params) {
			$post('/lock/setterInfo', params).then(() => {
				dispatch("getTableData");    //成功则返回页面
			})
		},
		modifyTableData({ dispatch, state }, params) {
			return new Promise((res => {
				$put('/lock/setterInfo', params).then(() => {
					dispatch("getTableData", state.params);
					res()
				})
			}))
		},
		deleteTableData({ dispatch, state }, id) {
			$delete(`/lock/setterInfo/${id}`).then(() => {
				dispatch("getTableData", state.params);
			})
		},
		getAllSchoolList({ commit }) {
			$get('/lock/universityInfo').then(res => {
				commit('setSchoolList', res.map(it => ({
					name: it.universityName,
					id: it.id
				})))
			})
		}
	},
	mutations: {
		setTableList: (state, payload) => {
			state.tableList = payload;
		},
		setTotal: (state, payload) => {
			state.total = payload;
		},
		setParams(state, payload) {
			state.params = payload
		},
		setSchoolList(state, payload) {
			state.schoolList = payload
		}
	},

}