import Vue from "vue";
import VueRouter from "vue-router";
import Layouts from "../views/Layouts.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layouts",
    component: Layouts,
    redirect: "/school",
    children: [
      {
        path: "school",
        component: () => import(/*webpackChunkName:"school" */"../views/school/index.vue")
      },
      {
        path: "white",
        component: () => import(/*webpackChunkName:"white" */"../views/white/index.vue")
      },
      {
        path: "safely",
        component: () => import(/*webpackChunkName:"safely" */"../views/safely/index.vue")
      },
      {
        path: "logs",
        component: () => import(/*webpackChunkName:"logs" */"../views/logs/index.vue")
      },
      {
        path: "notice",
        component: () => import(/*webpackChunkName:"notice" */"../views/notice/index.vue")
      },
      {
        path: "card",
        component: () => import(/*webpackChunkName:"card" */"../views/card/index.vue")
      },
      {
        path: "app",
        component: () => import(/*webpackChunkName:"card" */"../views/app/index.vue")
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
