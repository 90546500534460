import axios, { $get, $post, $put, $delete, $postFile } from '@/plugins/axios';
// import { setToken } from '@/views/common/store'
const tansParams = (params) => {
	let result = "";
	Object.keys(params).forEach((key) => {
		if (
			!Object.is(params[key], undefined) &&
			!Object.is(params[key], null)
		) {
			result +=
				encodeURIComponent(key) +
				"=" +
				encodeURIComponent(params[key]) +
				"&";
		}
	});
	return result;
}
export default {
	namespaced: true,
	state: {
		tableList: [],
		total: 0,
		params: null,
		mmPath: ""
	},
	actions: {
		getTableData({ commit }, params = {}) {
			commit("setParams", params);
			$get('/lock/postInfo/page', {
				params: {
					pageNum: 1,
					pageSize: 20,
					...params
				}
			}).then(res => {
				commit("setTableList", res.list);
				commit("setTotal", res.total);
			})
		},
		addTableData({ dispatch }, params) {
			$post('/lock/postInfo', params).then(() => {
				dispatch("getTableData");    //成功则返回页面
				return '1'
			})
		},
		modifyTableData({ dispatch, state }, params) {
			$put('/lock/postInfo', params).then(() => {
				dispatch("getTableData", state.params);
				return '1'
			})
		},
		deleteTableData({ dispatch, state }, id) {
			$delete(`/lock/universityInfo/${id}`).then(() => {
				dispatch("getTableData", state.params);
			})
		},
		addBlackList({ dispatch, state }, params){
			$post('/lock/blackList',params).then(()=>{
				dispatch("getTableData", state.params);
			})
		},
	
		upload({ dispatch, state }, file) {
			const fd = new FormData();
			console.log(file)
			fd.append('file', file)
			return $postFile('/lock/minio/upload', fd)
		}
	},
	mutations: {
		setTableList: (state, payload) => {
			state.tableList = payload;
		},
		setTotal: (state, payload) => {
			state.total = payload;
		},
		setParams(state, payload) {
			state.params = payload
		},
		setMMpath(state, payload) {
			state.mmPath = payload
		}
	},

}