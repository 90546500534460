<template>
  <div style="border: 1px solid #ccc">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor style="height: 500px; overflow-y: hidden" v-model="html" :defaultConfig="editorConfig" :mode="mode"
      @onCreated="onCreated" />
  </div>
</template>
<script>
import Vue from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { IToolbarConfig } from "@wangeditor/editor";
import { DomEditor } from "@wangeditor/editor";
export default Vue.extend({
  components: { Editor, Toolbar },
  props: {
    value: String,
    disabled: Boolean
  },
  data() {
    return {
      editor: null,
      html: this.value,
      toolbarConfig: {
        excludeKeys: ["emotion", "group-image", "group-video"], // 移除的配置
      },
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      if (this.disabled) {
        this.editor.disable()
      }
      const toolbar = DomEditor.getToolbar(editor);
      console.log(toolbar);
      const curToolbarConfig = toolbar.getConfig();
      console.log(curToolbarConfig.toolbarKeys); // 当前菜单排序和分组
    },
  },
  mounted() {

  },
  watch: {
    value(val) {
      this.html = val;
    },
    html(val) {
      this.$emit("input", val);
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
});
</script>
<style src="@wangeditor/editor/dist/css/style.css">

</style>