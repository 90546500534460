import { $post } from '../../plugins/axios';
import { setToken, setAuthorization, setPassWord } from '@/views/common/store'
const tansParams = (params) => {
	let result = "";
	Object.keys(params).forEach((key) => {
		if (
			!Object.is(params[key], undefined) &&
			!Object.is(params[key], null)
		) {
			result +=
				encodeURIComponent(key) +
				"=" +
				encodeURIComponent(params[key]) +
				"&";
		}
	});
	return result;
}
export default {
	namespaced: true,
	state: {
		username: ""
	},
	mutations: {
		setUsername: (state, payload) => {
			state.username = payload;
		}
	},
	actions: {
		login({ state }, param) {
			/// 登录接口
			console.log(state)
			return new Promise((res, rej) => {
				const params = {
					username: "",
					password: "",
					bindUsername: "",
					bindPassword: "",
					signUsername: "",
					signPassword: "",
					code: "",
					key: "",
					grant_type: "password",
					...param
				}
				$post('/auth/oauth/token', params, {
					transformRequest: [(params) => {

						return tansParams(params)
					}],
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Authorization': 'Basic ZmViczoxMjM0NTY=',
					},
					isLogin: true,
					needAll: true
				}).then((response) => {
					setToken(param.username);
					setAuthorization(response.data.access_token);
					setPassWord(params.password)
					res()
				}).catch(e => {
					rej(e)
				})
			})

		},
		getNewPass({ state }, params) {
			//  获取新密码
			console.log(params, state)
			return Promise.resolve("1234")
		},
		getPhoneEnd() {   //  获取手机尾号
			return Promise.resolve("1234")
		}
	}

}