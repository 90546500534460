import axios, { $postFile, $get, $post, $put, $delete, endLoading, startLoading } from '@/plugins/axios';
// import { setToken } from '@/views/common/store'
function dateFormat(fmt, date) {
	let ret;
	const opt = {
		"Y+": date.getFullYear().toString(), // 年
		"m+": (date.getMonth() + 1).toString(), // 月
		"d+": date.getDate().toString(), // 日
		"H+": date.getHours().toString(), // 时
		"M+": date.getMinutes().toString(), // 分
		"S+": date.getSeconds().toString(), // 秒
		// 有其他格式化字符需求可以继续添加，必须转化成字符串
	};
	for (let k in opt) {
		ret = new RegExp("(" + k + ")").exec(fmt);
		if (ret) {
			fmt = fmt.replace(
				ret[1],
				ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
			);
		}
	}
	return fmt;
}
export default {
	namespaced: true,
	state: {
		tableList: [],
		total: 0,
		params: null,
		schoolList: [],
	},
	actions: {
		getTableData({ commit }, params = {}) {
			commit("setParams", params);
			$get('/lock/whiteList/page', {
				params: {

					pageNum: 1,
					pageSize: 20,
					...params
				}
			}).then(res => {
				commit("setTableList", res.list);
				commit("setTotal", res.total);
			})
		},
		addTableData({ dispatch }, params) {
			$post('/lock/whiteList', params).then(() => {
				dispatch("getTableData");    //成功则返回页面
			})
		},
		modifyTableData({ dispatch, state }, params) {
			return new Promise(res => {
				$put('/lock/whiteList', params).then(() => {
					dispatch("getTableData", state.params);
					res()
				})
			})
		},
		deleteTableData({ dispatch, state }, id) {
			$delete(`/lock/whiteList/${id}`).then(() => {
				dispatch("getTableData", state.params);
			})
		},
		getAllSchoolList({ commit }) {
			$get('/lock/universityInfo').then(res => {
				commit('setSchoolList', res.map(it => ({
					name: it.universityName,
					id: it.id
				})))
			})
		},
		uploadExcelTemplate({ dispatch }, fd) {
			return new Promise((res, rej) => {

				$postFile('/lock/whiteList/importExcel', fd).then(() => {
					dispatch('getTableData');
					res();
				}).catch(e => rej(e))
			})
		},
		getExcel({ state }, params) {
			console.log(state.name)
			$get('/lock/whiteList/exportExcel', { params, responseType: "blob" }).then(res => {
			})
		},
		getExcelTemplate() {
			axios.get('/lock/whiteList/downloadExcelTemplate', { responseType: "blob" }).then(res => {
			})
		},
		getErrWhiteExcel({ state }, params) {
			$get('/lock/whiteList/downloadErrorExcel', { params, responseType: "blob" }).then(res => {
			})
		},
		batAddWhite({ dispatch }, list) {
			//  复制了学生端的代码，可以封装，只需要改新增的地址，懒的封装
			return new Promise(res => {
				let resonList = [];
				const batAddHostMaster = (idx = 0) => {
					const params = list[idx];
					if (!params) {
						endLoading();
						res(resonList)
						return;
					}
					params.startTime = params.times[0] ? dateFormat("YYYY-mm-dd HH:MM:SS", new Date(params.times[0])) : "";
					params.endTime = params.times[1] ? dateFormat("YYYY-mm-dd HH:MM:SS", new Date(params.times[1])) : "";
					delete params.times;
					delete params.reason
					$post('/lock/whiteList', params, { doNotNeedLoading: true, doNotNeedMessage: true }).then(res => {

					}).catch(e => {
						params.reason = e.data.message;
						resonList.push(params);
					}).finally(() => {
						batAddHostMaster(idx + 1)
					})
				}
				if (list.length) {
					startLoading();
					batAddHostMaster()
				}

			})
		}
	},
	mutations: {
		setTableList: (state, payload) => {
			state.tableList = payload;
		},
		setTotal: (state, payload) => {
			state.total = payload;
		},
		setParams(state, payload) {
			state.params = payload
		},
		setSchoolList(state, payload) {
			state.schoolList = payload
		}
	},

}