<template>
  <div class="layouts w-100_ h-100_">
    <div class="layouts-header d-f jc-sb ai-c">
      <div class="header-logo d-f ai-c jc-sb c-py">
        <img style="width: 32px" src="@/assets/logo.png" alt="" />
        <strong>洛克侠</strong>
      </div>

      <div class="d-f ai-c jc-sb logout">
        <span class="c-py" style="color: #fe6900">{{ username }}</span>

        <el-popover placement="bottom" width="200" trigger="click">
          <span class="c-p" slot="reference">退出登录</span>
          <div class="d-f ai-c">
            <img
              src="@/assets/warn.png"
              style="margin-right: 16px; width: 20px"
              alt=""
            />
            确定要退出登录吗？
          </div>
          <div style="margin-top: 16px" class="d-f ai-c w-100_">
            <el-button
              size="small"
              type="primary"
              class="w-100_"
              @click="logout"
              >退 出</el-button
            >
          </div>
        </el-popover>
      </div>
    </div>
    <div class="layouts-content d-f">
      <div class="tebs-checked h-100_">
        <div
          v-for="item in tabs"
          :key="item.name"
          @click="activeName = item.name"
          :class="
            'tebs-item  d-f ai-c c-p ' +
            (item.name == activeName ? 'active' : '')
          "
        >
          <img
            :src="item.name == activeName ? item.iconc : item.icon"
            style="margin: 0 24px"
            alt=""
          />
          <span>{{ item.label }}</span>
        </div>
      </div>
      <div class=" h-100_ tabs-content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { isLogin, getUser, clear } from "./common/store";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("system");
export default {
  name: "Layouts",
  data() {
    return {
      username: "",
      transitionName: "slide-left",
      activeName: this.$route.path.split("/").filter(Boolean)[0],
      tabs: [
        {
          name: "school",
          label: "学校管理",
          icon: require("../assets/school.svg"),
          iconc: require("../assets/school-c.svg"),
        },
        {
          name: "white",
          label: "会员白名单",
          icon: require("../assets/white.svg"),
          iconc: require("../assets/white-c.svg"),
        },
        {
          name: "notice",
          label: "通知公告",
          icon: require("../assets/notice.svg"),
          iconc: require("../assets/notice-c.svg"),
        },
        {
          name: "safely",
          label: "安装员管理",
          icon: require("../assets/safely.svg"),
          iconc: require("../assets/safely-c.svg"),
        },
        {
          name: "logs",
          label: "系统日志",
          icon: require("../assets/logs.svg"),
          iconc: require("../assets/logs-c.svg"),
        },
        {
          name: "card",
          label: "帖子管理",
          icon: require("../assets/white.svg"),
          iconc: require("../assets/white-c.svg"),
        },
        {
          name: "app",
          label: "app设置",
          icon: require("../assets/app.svg"),
          iconc: require("../assets/app-c.svg"),
        },
      ],
    };
  },
  methods: {
    ...mapActions(['getImageUrl']),
    logout() {
      clear();
      this.$router.push("/login");
    },
    handleClick() {},
  },
  beforeMount() {
    if (!isLogin()) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.username = getUser();
    this.getImageUrl()
  },
  watch: {
    activeName(val) {
      this.$route.path.split("/").filter(Boolean)[0] != val &&
        this.$router.push("/" + val);
    },
    $route() {
      if (!isLogin()) {
        this.$router.push("/login");
      }
      this.activeName = this.$route.path.split("/").filter(Boolean)[0];
      let isBack = this.$router.isBack; // 监听路由变化时的状态为前进还是后退
      if (isBack) {
        this.transitionName = "slide-right";
      } else {
        this.transitionName = "slide-left";
      }
      this.$router.isBack = false;
    },
  },
};
</script>
<style lang="scss" scoped>
$primaryColor: #e66700;
.layouts {
  .layouts-header {
    height: 50px;
    padding: 0 24px;
    .header-logo {
      img {
        margin-right: 8px;
      }
    }
    .menu-tabs {
      width: calc(100% - 400px);
      /deep/ .el-tabs__item {
        padding: 0 30px;
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
      /deep/ .el-tabs__nav-wrap {
        &::after {
          display: none;
        }
      }
    }
    .logout {
      color: rgba(0, 0, 0, 0.5);
      span {
        margin: 0 8px;
      }
    }
  }
  .layouts-content {
    height: calc(100vh - 60px);
    .tebs-checked {
      width: 200px;
      background: #3d3d3d;
      padding: 16px 0;
      .tebs-item {
        height: 50px;
        color: #fff;
        background: transparent;
      }
      .active {
        background: #f4f5f6;
        border-right: 4px solid #f08026;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .tabs-content {
      padding: 24px;
      background: #f4f5f6;
      width: calc(100% - 200px);
    }
  }
}
</style>
