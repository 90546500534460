"use strict";
import { Message } from "element-ui";
import axios from "axios";
import { Loading } from "element-ui";
// import { getSchoolId } from "../views/common/store";
import download from '../views/common/download';
import router from '../router/index';
import { getAuthorization } from '../views/common/store';
let loading;
export function startLoading() {
  loading = Loading.service({
    lock: true,
    text: "加载中……",
    background: "rgba(0, 0, 0, 0.7)",
  });
}
const clearWhite = (obj) => {
  if (obj) {
    if (typeof obj == "string") {
      return obj.replace(/^\s*|\s*$/g, "")
    } else if (Array.isArray(obj)) {
      return obj.reduce((c, n) => [...c, clearWhite(n)], [])
    } else if (typeof obj == "object") {
      console.log(obj)
      return Object.keys(obj).reduce((c, n) => ({ ...c, [n]: clearWhite(obj[n]) }), {})
    }
  }
  return obj
}
export function endLoading() {
  loading.close();
}
var TT;
export function errMessage(val) {
  clearTimeout(TT);
  console.log(val)
  TT = setTimeout(() => {
    Message({ message: val, type: "error" });
  }, 500);
}
// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    !config.isLogin && (config.headers.Authorization = getAuthorization());
    !config.doNotNeedLoading && startLoading();
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    endLoading();
    if (response.config.responseType && response.config.responseType == 'blob') {
      const filename = response.headers["content-disposition"].split(';')[1].split('filename=')[1];
      download(response.data, decodeURIComponent(filename));
      return response.data
    }
    if (response.config.needAll) {
      return response
    }
    if (response.data.code == 200) {
      return response.data.data;
    } else if (response.config.isLogin) {
      return response.data.data;
    } else {
      console.log(response?.data)
      !response.config.doNotNeedMessage && errMessage(response?.data?.message);
      return Promise.reject(response);
    }
  },
  function (error) {
    // Do something with response error
    console.error([error])
    !error.config.doNotNeedMessage && errMessage(error?.response?.data?.message);
    if (error?.response?.status == 401) {
      router.push('/login')
    }
    endLoading();
    return Promise.reject(error);
  }
);
export default _axios;
export const $get = (url, config) =>
  _axios.get(url, {
    ...(config || {}),
    params: {
      ...(config && config.params ? config.params : {}),
      // universityId: getSchoolId(),
    },
  });
export const $post = (url, data, config) =>
  _axios.post(url, {
    ...clearWhite(data),

    // universityId: getSchoolId()

  }, config);
// export const $post = (url, data) => _axios.post(url + '?' + (Object.keys(data).reduce((c, n) => c + (c ? '&' : '') + n + '=' + data[n], '')))
export const $put = (url, data) => {
  data.modifyTime && delete data.modifyTime;
  data.createTime && delete data.createTime;
  return _axios.put(url, {
    ...clearWhite(data),
    //  universityId: getSchoolId() 
  })
};
// export const $put = (url, data) => _axios.put(url + '?' + (Object.keys(data).reduce((c, n) => c + (c ? '&' : '') + n + '=' + data[n], '')))

export const $delete = (url, config) => _axios.delete(url, config);
export const $postFile = (url, fd, config) => {

  return _axios.post(url, fd, config);
}