import { $get, $post } from '@/plugins/axios';
// import { setToken } from '@/views/common/store'
const getBuildFloorRoom = (list, buildName) => {
	const params = {};
	const buildParams = {};
	const storeBuild = {};  // 记录楼栋的Id 名称
	const topList = [];
	list.forEach(item => {
		if (params[item.buildingId + '-' + item.floorNo]) {   // 首先查找楼层
			params[item.buildingId + '-' + item.floorNo].push({ ...item, checked: false, label: item.roomNo })
		} else {
			params[item.buildingId + '-' + item.floorNo] = [{ ...item, checked: false, label: item.roomNo }]
		}
		if (buildParams['build-' + item.buildingId]) {
			if (!buildParams['build-' + item.buildingId][item.buildingId + '-' + item.floorNo]) {
				buildParams['build-' + item.buildingId][item.buildingId + '-' + item.floorNo] = { id: item.floorNo, label: '第' + item.floorNo + '层', children: params[item.buildingId + '-' + item.floorNo] }
			}
		} else {
			buildParams['build-' + item.buildingId] = { [item.buildingId + '-' + item.floorNo]: { id: item.floorNo, label: '第' + item.floorNo + '层', children: params[item.buildingId + '-' + item.floorNo] } }
		}
		storeBuild[item.buildingId] = buildName[item.buildingId] || item.buildingId
	})
	Object.keys(buildParams).forEach(item => {
		const id = item.split('-')[1];
		if (id != 'null' && id != null) {
			topList.push({ id, label: storeBuild[id], children: Object.values(buildParams[item]) })
		}
	});
	return topList
};
const getList = (item) => {
	if (item.children) {
		return item.children.map((it) => getList(it)).filter(Boolean);
	} else {
		return item.checked ? item : false;
	}
};
export default {
	namespaced: true,
	state: {
		managerList: [],
	},
	actions: {

		getAllSchoolList({ commit }) {


			const getSchoolDetails = (id) => {
				return new Promise(resnse => {
					$get('/lock/buildingInfo?universityId=' + id).then(response => {
						let buildingNameObject = {};
						response.forEach((item) => {
							buildingNameObject[item.id] = item.buildingName;
						});
						$get('/lock/roomInfo?universityId=' + id).then(res => {
							let resList = res;
							const schoolBuildList = getBuildFloorRoom(resList, buildingNameObject);
							resnse(schoolBuildList)
						})
					})
				})
			};
			$get('/lock/universityInfo').then(res => {
				let schoolList = res.map(item => ({
					id: item.id,
					label: item.universityName
				}))
				Promise.all(schoolList.map(it => getSchoolDetails(it.id))).then(list => {
					schoolList = schoolList.map((it, idx) => ({ ...it, children: list[idx] }))
					commit('setManagerList', schoolList);

				})
			})
		},
		addNotice({ commit }, params) {
			console.log(commit)
			if (Array.isArray(params.sendTarget)) {
				const paramsList = params.sendTarget.map(it => ({
					...params,
					universityId: it.id,
					sendTarget: getList(it).flat(Infinity)
						.map((it) => it.id)
						.join(",")
				})).filter(it => it.sendTarget);
				return Promise.all(paramsList.map(params => $post('/lock/noticeInfo', params)))
			} else {

				return $post('/lock/noticeInfo', params)
			}
		}
	},
	mutations: {
		setManagerList(state, payload) {
			state.managerList = payload
		}
	},

}