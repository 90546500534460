import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "./element-variables.scss";
import MyEditor from './components/myEditor.vue'
import MyInput from './components/myInput.vue'
Vue.config.productionTip = false;
Vue.component("my-editor", MyEditor);
Vue.component("my-input", MyInput);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
