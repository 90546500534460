<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "./index.scss";
export default {
  name: "app",
  mounted() {
    console.log(this.$store);
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-width: 1440px;
}
</style>
