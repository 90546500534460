import { $get } from '@/plugins/axios';
// import { setToken } from '@/views/common/store'
import download from '../../views/common/download';
export default {
	namespaced: true,
	state: {
		tableList: [],
		total: 0,
		params: null,
	},
	actions: {
		getTableData({ commit }, params = {}) {
			commit("setParams", { params });
			$get('/lock/operateLog/page', {
				params: {
					pageNum: 1,
					pageSize: 20,
					...params
				}
			}).then(res => {
				commit("setTableList", res.list);
				commit("setTotal", res.total);
			})
		},
		getExcel({ state }, params) {
			console.log(state.name)
			$get('/lock/operateLog/exportExcel', { params, responseType: "blob" }).then(res => {
				download(res, '白名单管理导出数据')
			})
		},
	},
	mutations: {
		setTableList: (state, payload) => {
			state.tableList = payload;
		},
		setTotal: (state, payload) => {
			state.total = payload;
		},
		setParams(state, payload) {
			state.params = payload
		}
	},

}