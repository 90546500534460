import { $get, $post } from '../../plugins/axios';
export default {
	namespaced: true,
	state: {
		imageUpload: ""
	},
	mutations: {
		setImageUrl: (state, payload) => {
			state.imageUpload = payload;
		}
	},
	actions: {

		getImageUrl({ state }, params) {
			//  获取新密码

			$get('/lock/systemParameter/getSystemParameterByCode', { params: { paramCode: "imageUploadAddress" } }).then(res => {
				state.imageUpload = res?.paramValue ?? 'http://112.126.61.25:9001'
			})
		},
	}

}